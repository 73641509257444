const Denied = () => (
  <div
    className='d-flex justify-content-center align-items-center'
    style={{
      height: '100vh',
    }}
  >
    <img
      src='https://c.tenor.com/4DaW_M5ABPkAAAAC/cringe-nenechi.gif'
      alt='Nope'
    />
  </div>
);

export default Denied;
