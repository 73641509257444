const ActionTypes = {
  LOAD_SESSION: 'LOAD_SESSION',
  SET_SESSION: 'SET_SESSION',
  RECEIVE_VIDEOS: 'RECEIVE_VIDEOS',
  PUT_VIDEO: 'PUT_VIDEO',
  REMOVE_VIDEO: 'REMOVE_VIDEO',
  RECEIVE_DIRECTORIES: 'RECEIVE_DIRECTORIES',
  PUT_DIRECTORY: 'PUT_DIRECTORY',
  REMOVE_DIRECTORY: 'REMOVE_DIRECTORY',
  RECEIVE_TAGS: 'RECEIVE_TAGS',
  MANAGEMENT_SELECT_DIRECTORY: 'MANAGEMENT_SELECT_DIRECTORY',
  MANAGEMENT_SELECT_VIDEOS: 'MANAGEMENT_SELECT_VIDEOS',
  MANAGEMENT_RESET_SELECTIONS: 'MANAGEMENT_RESET_SELECTIONS',
  MANAGEMENT_SET_UNSAVED_VIDEOS: 'MANAGEMENT_SET_UNSAVED_VIDEOS',
};

export default ActionTypes;
